<script>
import RedirectNewView from './RedirectNewViewVlm'

export default {
  name: 'RedirectEditViewVlm',
  extends: RedirectNewView,
  async created () {
    await this.getRedirect()
  }
}
</script>
