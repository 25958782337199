<template>
  <RedirectEditView disabled />
</template>

<script>
import RedirectEditView from '@/views/redirect/vlm/RedirectEditViewVlm'

export default {
  name: 'RedirectViewVlm',
  components: {
    RedirectEditView
  }
}
</script>
